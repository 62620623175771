import "./App.css";
import SceneHandler from "./components/scene/sceneHandler";
import Base from "./components/skeleton/skeleton";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Terms from "./components/pages/termsPage";
import About from "./components/pages/aboutPage";
function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route
          path="/"
          exact
          element={
            <div className="App">
              <header className="App-header">
                <Base props={SceneHandler} />
              </header>
            </div>
          }
        />
        <Route
          path="/home"
          exact
          element={
            <div className="App">
              <header className="App-header">
                <Base props={SceneHandler} />
              </header>
            </div>
          }
        />

        <Route path="/terms" exact element={<Terms />} />
        <Route path="/about" exact element={<About />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
