import React from "react";
import myImage from "./logo.jpeg";

import { redirectHP } from "../../host";
import Logo from "../logo/logo";

export default function Header() {

  function redirectToModelApp() {
    window.location.href = redirectHP.protocol + redirectHP.host + ":" + redirectHP.port;
    // console.log(redirectHP);
  }

  return (
    <div style={{background: "rgba(255,255,255,0.9)", height:"90px", width:"100vw", backdropFilter: 'blur(5px)'}}>
      {/* <img
        className="header--image"
        src={myImage}
        onClick={() => redirectToModelApp()}
        alt="logo"
      /> */}
      <div className="header--image" onClick={redirectToModelApp}>
        <Logo />
      </div>
    </div>
  );
}
