import "./pages.css";
// import Logo from "./logo.jpeg";
import Footer from "../footer/footer";
// import HP from "../../host";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Header from "../Header/Header";
import Container from "@mui/material/Container";

const theme = createTheme({
  palette: {
    type: "light",
  },
});

export default function About() {
  // function redirectToModelApp() {
  //   window.location.href = "https://"+ HP.host +":3000"
  // }

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Container>
        <div className="centered-component">
          <h2 className="centered-heading">About us</h2>
          <div className="centered-paragraphs">
            <p className="distinct-paragraph">
              Dental Shelter is a web-based application that provides a basic
              dental health issue detection tool for individuals who may not
              have access to routine dental care due to financial limitations,
              lack of dental insurance, or other barriers. It does not provide
              an actual diagnosis, but rather empowers people to be aware of
              their oral health and provides over-the-counter self-help
              solutions, free of charge.
            </p>
            <p className="distinct-paragraph">
              We understand that dental care can be expensive, and we want to
              make sure that everyone has access to the tools they need to
              maintain good oral health. Our goal is to help people improve
              their oral health and live healthier lives, while also generating
              the resources necessary to continue our work.
            </p>
            <p className="distinct-paragraph">
              Dental Shelter is a web app that helps people improve their oral
              health. The app provides users with a personalized oral health
              journey and educational resources about dental health, whilst
              removing the complexity.
              <p className="distinct-paragraph" style={{ textAlign: "left" }}>
                <br></br>Here are some of the features that make it so easy to
                use:
              </p>
              <ul className="list">
                <li>
                  <b>A simple interface: </b>The app has a simple, user-friendly
                  interface that is easy to navigate.
                </li>
                <li>
                  <b>Step-by-step instructions: </b>The app provides
                  step-by-step instructions for all of the dental care tasks,
                  making it easy to follow along.
                </li>
                <li>
                  <b>Visual aids: </b>The app uses visual aids, such as photos
                  and 3D, to help users understand the dental care tasks.
                </li>
                <li>
                  <b>Personalized feedback: </b>The app provides personalized
                  feedback on users' progress, helping them to stay on track.
                </li>
              </ul>
              <p>
                Dental Shelter was created by a team of dentists and software
                developers who are passionate about helping people achieve and
                maintain good oral health.
              </p>
            </p>
          </div>

          <h4 className="centered-heading" style={{ textAlign: "left" }}>
            Our values
          </h4>
          <div className="centered-paragraphs">
            <div className="distinct-paragraph">
              <p className="" style={{ textAlign: "left" }}>
                <br></br>We believe in the following values:
              </p>
              <ul className="list">
                <li>
                  <b>Affordability: </b>We believe that everyone should have
                  access to quality dental care, regardless of their income. We
                  offer our app for free, so that everyone can benefit from our
                  resources.
                </li>
                <li>
                  <b>Convenience: </b> We believe that people should be able to
                  access dental care when and where they need it. Our app is
                  available on mobile devices, so that people can use it from
                  anywhere.
                </li>
                <li>
                  <b>Personalized feedback: </b>The app provides personalized
                  feedback on users' progress, helping them to stay on track.
                </li>
              </ul>
              <p>
                We are committed to helping people achieve and maintain good
                oral health.
              </p>
            </div>
          </div>
        </div>
        <div style={{ height: "3vw" }}></div>
        <Footer />
      </Container>
    </ThemeProvider>
  );
}
