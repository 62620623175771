import myDemo from "./teeth.mp4";
import React from "react";
import "./userDemo.css";
import "../skeleton/assets/skeleton.css";

export default function UserDemo(props) {
  return (
    <video
      src={myDemo}
      className="demo"
      title="jaw"
      frameBorder="0px"
      onClick={(e) => {
        props.funcClose(e);
      }}
      autoPlay="autoplay"
      muted
      loop
    />
  );
}
