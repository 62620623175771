import "./assets/skeleton.css";
import LogoOld from "./assets/logo.jpeg";
// import Logo192 from './assets/logo192.png';
import Footer from "../footer/footer";
// import Grid from "@mui/material/Grid";
// import HP from "../../host";
import { redirectHP } from "../../host";
import { ThemeProvider, createTheme } from "@mui/material/styles";
// import orange from '@mui/material/colors/orange';
import Container from "@mui/material/Container";
// import Header from "../Header/Header";
import Logo from "./../logo/logo";

const theme = createTheme({
  palette: {
    type: "light",
  },
});

export default function Base(props) {
  function redirectToModelApp() {
    window.location.href = redirectHP.protocol + redirectHP.host + ":" + redirectHP.port;
  }
  // component to be passed inside base
  const Component = props.props;
  //console.log(props.props);
  return (
    // <ThemeProvider theme={theme}>
    //   {/* <Grid container spacing={1}> */}
    //   <Grid container>
    //     {/* <div className="Skeleton"> */}
    //       {/* <Grid item xs={4} sm={6} md={8} lg={10} xl={12}> */}
    //         <img src={Logo} alt="logo" className="logo" />
    //       {/* </Grid> */}
    //       <div className="Component">
    //         <Grid container item spacing={3} style={{
    //           padding: "0px",
    //         }}>
    //           <Grid item xs={12} sm={14} md={16} lg={18} xl={20}>
    //             <Component />
    //           </Grid>
    //         </Grid>
    //       </div>
    //     {/* </div> */}
    //   </Grid>
    //   {/* </Grid> */}
    // </ThemeProvider>

    <ThemeProvider theme={theme}>
      <Container
        style={{
          margin: "0px",
          padding: "0px",
          minWidth: "98vw",
        }}
      >
        {/* <div className="Skeleton"> */}
        {/* <img
          src={LogoOld}
          alt="logo"
          className="logo"
          onClick={() => redirectToModelApp()}
        /> */}
        <div className="header--image" onClick={redirectToModelApp}>
          <Logo />
        </div>
        <div className="Component">
          <Component />
        </div>
        <Footer />
        {/* </div> */}
      </Container>
    </ThemeProvider>
  );
}
