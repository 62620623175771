import "./scene.css";
import React, { Suspense, useRef, useState, useEffect } from "react";
import {
  Canvas,
  extend,
  useLoader,
  useFrame,
  useThree,
} from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import sceneUrl from "./dental03.glb";
extend({ OrbitControls });

// Touch device start ==================================

function isTouchDevice() {
  let userAgent = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;
  let isMobileDevice = regexp.test(userAgent);

  if (isMobileDevice) {
    return true;
  } else {
    return false;
  }
}

// Touch device ends ===================================

// Highlight on Hover Start ============================
function hoverOver(intersects, event, active) {
  if (intersects === undefined && !active) return;
  else if (intersects.object.material.emissive.getHex() === 254) {
    return;
  } else if (intersects.object !== event.intersections[0]) {
    if (intersects.object) {
      intersects.object.material.emissive.setHex(intersects.object.currentHex);
    }

    intersects = event.intersections[0];
    intersects.object.currentHex = intersects.object.material.emissive.getHex();
    intersects.object.material.emissive.setHex(0xff0000);
    intersects.object.material.emmisiveIntensity = 1.5;
    //console.log(intersects.object)
  }
  return;
}
function hoverOut(intersects, event, active) {
  if (intersects !== undefined && intersects.object) {
    //if (!active){
    intersects.object.material.emissive.setHex(intersects.object.currentHex);
    //}
    //else{
    //  intersects.object.material.emissive.setHex(0x00ff00)
    //  active= false;
    //}
    intersects.object.material.emmisiveIntensity = 1;
    //console.log(intersects.object)
    intersects.object = null;
  }

  return;
}
// Highlight on Hover Finish =========================

// Highlight on Click Start =========================
function generalizeSelected(objArray) {
  let model = [];
  const tongueArray = objArray.filter((tongue) => tongue.name === "Tongue");
  const teethArray = objArray.filter((teeth) => /\b\d/.test(teeth.name));
  const gumsArray = objArray.filter((gums) => /\bg\d/.test(gums.name));

  if (tongueArray.length > 0) {
    model["tongue"] = true;
  } else {
    model["tongue"] = false;
  }
  if (teethArray.length > 0) {
    model["teeth"] = true;
  } else {
    model["teeth"] = false;
  }
  if (gumsArray.length > 0) {
    model["gums"] = true;
  } else {
    model["gums"] = false;
  }
  return model;
}

function objectArrayHandler(objArray, obj) {
  //objArray.push(obj.name)
  objArray.push(obj);
  let objRemoved = [];
  if (objArray.filter((dupe) => dupe === obj).length >= 2) {
    objRemoved = objArray.filter((x) => x === obj);
    objArray = objArray.filter((x) => x !== obj);
    for (let i = 0; i < objRemoved.length; i++) {
      if (objRemoved[i] != null) {
        objRemoved[i].currentHex = 0;
        objRemoved[i].material.emissive.setHex(0);
        objRemoved[i].material.emmisiveIntensity = 1;
      }
    }
  }
  console.log(objArray);
 

  const selected = generalizeSelected(objArray);
  if (selected.tongue) {
    console.log("Tongue");
  }
  if (selected.teeth) {
    console.log("Teeth");
  }
  if (selected.gums) {
    console.log("Gums");
  }

  return objArray;
}

function returnSelected(objArray) {
  const selected = generalizeSelected(objArray);
  return selected;
  // if (selected.tongue) {
  //   return
  // }
  // if (selected.teeth) {
  //   console.log("Teeth")
  // }
  // if (selected.gums) {
  //   console.log("Gums")
  // }
}

function clickDownArraySupported(objArray, event, active) {
  if (!(objArray === undefined || objArray.length === 0)) {
    // console.log('Currently highlighted: ' + objArray.length)
    // console.log(objArray)
    for (let i = 0; i < objArray.length; i++) {
      if (objArray[i] !== undefined && objArray[i] !== null) {
        objArray[i].currentHex = 254;
        objArray[i].material.emissive.setHex(0x0000ff);
        objArray[i].material.emmisiveIntensity = 1.5;
      }
    }
  }
  return;
}

// global arrays - won't work otherwise
let activeObjects = [];
let disabledObjects = ["Gum_Upper_Base", "Gum_Lower_Base"];

// Scene - all things model
function Model(props) {
  const gltf = useLoader(GLTFLoader, sceneUrl);
  const model = gltf.scene;
  useFrame((state) => {
    model.position.y = -17;
    model.updateMatrix();
    model.updateWorldMatrix();
  });
  //const modelRef = useRef()
  //model.position.y = -17;
  //model.updateMatrix();
  model.traverse((e) => {
    if (e.name.length > 0) {
      if (e.material) e.material = e.material.clone();
    }
  });
  const [active, setActive] = useState(false);

  // for rotation animation
  const [rotateValue, setRotateValue] = useState(0);
  // for highlight animation
  const [highlightState, setHighlightState] = useState(false);

  let intersects;
  // let isTouch = isTouchDevice()
  // console.log("Touch device: ", isTouch)

  //=====================================================
  // Rotates the model when null
  useEffect(() => {
    if (props.rotateCallFunc[1]) {
      if (rotateValue === 0) {
        setRotateValue(0.05);
      }
      /*else {
        setRotateValue(0);
      }*/
      props.rotateCallFunc[0](100);
    }
  }, [rotateValue, props.rotateCallFunc]);
  // Rotate ends
  //=====================================================

  //=====================================================
  // Highlight the model when null
  useEffect(() => {
    if (props.highlightCallFunc[1]) {
      console.log("HL...");
      // add objects to be hled
      let objs = [];
      for (let i = 0; i < 16; i++) {
        //let index = Math.floor(Math.random() * model.children.length);
        let index1 = i + 19; // lower teeth
        let index2 = i + 3; // upper teeth

        let obj1 = model.children[index1];
        let obj2 = model.children[index2];
        objs.push(obj1);
        objs.push(obj2);
      }
      setTimeout(() => {
        // highlight
        for (let i = 0; i < model.children.length; i++) {
          if (objs[i] !== undefined && objs[i] !== null) {
            objs[i].currentHex = 254;
            objs[i].material.emissive.setHex(0xff00ff);
            objs[i].material.emmisiveIntensity = 5;
          }
        }
        console.log(objs);
      }, 1650);

      setTimeout(() => {
        console.log("......");
        // dehighlight
        for (let i = 0; i < objs.length; i++) {
          if (objs[i] !== undefined && objs[i] !== null) {
            objs[i].currentHex = 0;
            objs[i].material.emissive.setHex(0);
            objs[i].material.emmisiveIntensity = 1;
          } //model.children
        }
        console.log(objs);
        props.highlightCallFunc[0](100);
      }, 2000);
    }
  }, [model, props.highlightCallFunc]);
  // Highlight ends
  //=====================================================

  useEffect(() => {
    // console.log(props);
    // console.log(props.selectedFunc);
    props.selectedFunc[0](returnSelected(activeObjects));
  }, []);

  //=====================================================
  useFrame(() => model.updateWorldMatrix(true, true));

  // !!!!!!!!!!!!!!!!!!!! IMPORTANT !!!!!!!!!!!!!!!!!!!!!!
  // This event listener is only for debugging purposes. Remove from final build
  useEffect(() => {
    //console.log(props.isEventAttachedFunc[1])
    if (!props.isEventAttachedFunc[1]) {
      window.addEventListener("keydown", (event) => {
        console.log("eveeeveveveeveve");
        if (event.key === "r") {
          console.log(props.rotateCall);
          //rotateModel(model);
          if (rotateValue === 0) {
            setRotateValue(0.04);
            console.log("Rotate after set: " + rotateValue);
            console.log("Set rotation to 0.01");
          } else {
            setRotateValue(0);
            console.log("Set rotation to 0");
          }
        }
        if (event.key === "m") {
          //console.log(model.rotation.y);
          //console.log(rotateValue);
        }

        if (event.key === "h") {
          console.log("Highlight State: ", highlightState);
          let objs = [];
          for (let i = 0; i < 16; i++) {
            //let index = Math.floor(Math.random() * model.children.length);
            let index = i + 19;
            let obj = model.children[index];
            objs.push(obj);
          }

          if (!highlightState) {
            setHighlightState(true);
            console.log("HL " + model.children.length);
            for (let i = 0; i < model.children.length; i++) {
              if (objs[i] !== undefined && objs[i] !== null) {
                objs[i].currentHex = 254;
                objs[i].material.emissive.setHex(0xff00ff);
                objs[i].material.emmisiveIntensity = 5;
              }
            }
          }

          if (highlightState) {
            setHighlightState(false);
            console.log("De-HL " + model.children.length);
            for (let i = 0; i < objs.length; i++) {
              if (objs[i] !== undefined && objs[i] !== null) {
                objs[i].currentHex = 0;
                objs[i].material.emissive.setHex(0);
                objs[i].material.emmisiveIntensity = 1;
              } //model.children
            }
          }
        }
      });

      props.isEventAttachedFunc[0](true);
    }
  }, [model, rotateValue, highlightState, props]);
  // event listener ends - remove till here

  useFrame(() => {
    const limitDegree = 60;
    model.rotation.y += rotateValue;
    // degrees * PI/180
    if (model.rotation.y >= (limitDegree * Math.PI) / 180) {
      //model.rotation.y = 0;
      setRotateValue(-1 * rotateValue);
    }
    if (model.rotation.y <= (-limitDegree * Math.PI) / 180) {
      //model.rotation.y = 0;
      setRotateValue(-1 * rotateValue);
    }
    if (rotateValue >= 0 && model.rotation.y < 0) {
      if (model.rotation.y + rotateValue > 0) {
        setRotateValue(0);
        model.rotation.y = 0;
      }
    }
  });

  // passing data from scene to sceneHandler
  props.modelDataFunc(activeObjects);

  return (
    <Suspense fallback={null}>
      <primitive
        object={gltf.scene}
        position={(0, -15, 0)}
        scale={500}
        onClick={(event) => {
          event.stopPropagation();
          //event.preventDefault()
          setActive(!active);
          // ++++
          if (intersects === undefined) {
            intersects = event.intersections[0];
          }

          // testing
          console.log(intersects[0]);
          console.log(event.intersections[0]);
          intersects = event.intersections[0];

          console.log("ray: ", intersects.distance);

          // array handler
          if (intersects !== (undefined || null)) {
            //console.log("inter.obj: ", intersects.object)
            if (
              intersects.object != null &&
              !disabledObjects.includes(intersects.object.name)
            ) {
              //console.log("io")
              activeObjects = objectArrayHandler(
                activeObjects,
                intersects.object
              );
              // console.log('ip')
            }
          }
          // highlight functionality
          // clickDown(intersects.object, event, true)
          clickDownArraySupported(activeObjects, event, true);
          //console.log(active)
        }}
        onPointerOver={(event) => {
          event.stopPropagation();
          intersects = event.intersections[0];
          if (!disabledObjects.includes(intersects.object.name)) {
            hoverOver(intersects, event, active);
          }
        }}
        onPointerOut={(event) => {
          event.stopPropagation();
          if (intersects !== undefined && intersects !== null) {
            try {
              if (!disabledObjects.includes(intersects.object.name)) {
                hoverOut(intersects, event, active);
              }
            } catch (error) {
              console.log("Error occured in pointer out");
              console.log(intersects);
            }
          }
        }}
      >
        {" "}
      </primitive>
    </Suspense>
  );
}

function intersectionsFilter(intersections) {
  return intersections?.length ? [intersections[0]] : intersections;
}

export default function Scene(props) {
  return (
    <div className="Scene" id="canvas">
      <Canvas
        orthographic
        camera={{
          zoom: 9,
          position: [0, 0, 50],
          near: 0.0000001,
          target: [0, 0, 0],
          top: 1,
          bottom: 10,
          left: 20,
          right: 20,
        }}
        raycaster={{ filter: intersectionsFilter }}
        antialias={"true"}
        onPointerDown={(event) => {
          if (isTouchDevice()) {
            console.log("mobile down");
          }
        }}
      >
        {/*<color attach="background" args={["rgb(100, 100, 120)"]} />*/}
        <CameraControls />
        <ambientLight args={[0xff0000]} intensity={0.1} />
        <directionalLight position={[0, 0, 5]} intensity={0.75} />
        <Model
          modelDataFunc={props.modelDataFunc}
          isEventAttachedFunc={props.isEventAttachedFunc}
          rotateCallFunc={props.rotateCallFunc}
          highlightCallFunc={props.highlightCallFunc}
          selectedFunc={props.selectedFunc}
        />
      </Canvas>
    </div>
  );
}

function CameraControls() {
  const {
    camera,
    gl: { domElement },
  } = useThree();

  // Ref to the controls, so that we can update them on every frame using useFrame
  const controls = useRef();
  // camera.position.set(500, 30, 30);
  useFrame((state) => controls.current.update());
  useFrame(() => camera.updateProjectionMatrix());

  //console.log(camera.position)
  return (
    <orbitControls
      ref={controls}
      args={[camera, domElement]}
      maxPolarAngle={Math.PI / 2}
      minPolarAngle={Math.PI / 2}
      minAzimuthAngle={-Math.PI / 2}
      maxAzimuthAngle={Math.PI / 2}
      enablePan={false} // disables panning
      enableZoom={false} // disables zoom
    />
  );
}
