import React from "react";
import Scene from "./scene";
import UserDemo from "../userDemo/userDemo";
import "./scene.css";
import axios from "axios";
import HP, { redirectHP } from "../../host";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
// import Paper from '@mui/material/Paper';
// import { styled } from '@mui/material/styles';
// import Grid from '@mui/material/Grid';

import { ThemeProvider, createTheme } from "@mui/material/styles";
// import orange from '@mui/material/colors/orange';
import blue from "@mui/material/colors/blue";
// import { Container } from "@mui/system";

import ReCAPTCHA from "react-google-recaptcha";

const theme = createTheme({
  palette: {
    primary: {
      main: blue[600],
    },
  },
});

// const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
//     border: '0px solid #e0e0e0',

// }));

// async function getAllChoices() {
//   try {
//     const response = await fetch("http://localhost:80/choices");
//     // response.header("Access-Control-Allow-Origin", "*");
//     return await response.json();
//   } catch (error) {
//     console.log("Couldn't get api/choices ", error);
//     return [];
//   }
// }

export default function SceneHandler() {
  const [modelData, setModelData] = React.useState([]);
  const [isEventAttached, setIsEventAttached] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  // const [choices, setChoices] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  let Selected;

  //recaptcha
  const [showRecaptcha, setShowRecaptcha] = React.useState(false);
  const [verificationResult, setVerificationResult] = React.useState(null);

  // rotate state - is used to tell the program to rotate the model if nothing is selected
  const [rotateCall, setRotateCall] = React.useState(false);
  // highligh state - is used to tell the program to highlight the model if nothing is selected
  const [highlightCall, setHighlightCall] = React.useState(false);
  const [screen, setScreen] = React.useState({ width: -1, height: -1 });

  const modelDataFunc = (modeldata) => {
    //console.log("Model Data Before: ", modelData);
    setModelData(modeldata);
    //console.log("Model Data After: ", modelData);
  };
  const isEventAttachedFunc = (isEventAttached) => {
    setIsEventAttached(isEventAttached);
  };
  const rotateCallFunc = (modelLength) => {
    //console.log("Model Length: " + modelLength);
    //console.log("rotateCall Value(sceneHandler - before): ",rotateCall, " Model Length: ", modelLength);
    if (modelLength > 0) {
      //console.log("Rotate Call Value(sceneHandler - before): ",rotateCall, " Model Length: ", modelLength);
      setRotateCall(false);
      console.log("Setting rotateCall to false - Model Length", modelLength);
      //console.log("Rotate Call Value(sceneHandler - after): ",rotateCall, " Model Length: ", modelLength);
    }
    // else if (modelLength === 0) {
    //     //console.log("Rotate Call Value(sceneHandler - before): ",rotateCall, " Model Length: " + modelLength);
    //     setRotateCall(true);
    //     console.log("Setting rotateCall to true - Model Length", modelLength);
    //     //console.log("Rotate Call Value(sceneHandler): after",rotateCall, " Model Length: " + modelLength);
    // } 
    //console.log("rotateCall (sceneHandler - after): ",rotateCall, " Model Length: ", modelLength);
  };
  const setHighlightCallFunc = (modelLength) => {
    if (modelLength > 0) {
      setHighlightCall(false);
    }
    // else if (modelLength === 0) {
    //     setHighlightCall(true);
    // }
  };

  const selectedFunc = (data) => {
    setSelected(data);
    console.log("Selected [base] : ", selected);
  };

  // useEffect(() => {
  //     getAllUsers()
  //         .then(users => {
  //             console.log(users)
  //             // setUsers(users);
  //             // setNumberOfUsers(users.length)
  //         });
  // }, [])
  function selectionHandler(objArray) {
    let model = [];
    const tongueArray = objArray.filter((tongue) => tongue.name === "Tongue");
    const teethArray = objArray.filter((teeth) => /\b\d/.test(teeth.name));
    const gumsArray = objArray.filter((gums) => /\bg\d/.test(gums.name));

    if (tongueArray.length > 0) {
      model["tongue"] = true;
    } else {
      model["tongue"] = false;
    }
    if (teethArray.length > 0) {
      model["teeth"] = true;
    } else {
      model["teeth"] = false;
    }
    if (gumsArray.length > 0) {
      model["gums"] = true;
    } else {
      model["gums"] = false;
    }

    return model;
  }

  async function onClickDo(e) {
    rotateCallFunc(modelData.length);
    setHighlightCallFunc(modelData.length);
    if (modelData.length > 0 && rotateCall === false) {
      console.log("Will proceed - Will not rotate");
      Selected = selectionHandler(modelData);

      let temp = [];
      let i = 0;
      while (i < modelData.length) {
        console.log(modelData[i].name);
        temp[i] = modelData[i].name;
        i++;
      }
      console.log(temp);

      await axios
        .post(HP.protocol + HP.host + ":" + HP.port + "/api/choices", temp)
        .then((res) => {
          console.log("Selection sent to server", res);
          if (res.data === "recaptcha") {
            setShowRecaptcha(true);
          } else {
            sendSelected(Selected);
          }
        })
        .catch((err) => {
          console.log("Couldn't send selection to server", err);
        });

      // setSelected( selectionHandler(modelData), () => sendSelected(selected));
      // setTimeout(() => {
      //     console.log('-----', selected);
      //     // console.log('=====', selected);

      //     // console.log('sent data!!!!!!!!');
      // } , 5000)

      console.log("Selected are => ", selected);
      // getAllChoices()
      //     .then(choices => {
      //         console.log("Choice: ", choices);
      //         // console.log(choices)
      //         // setUsers(users);
      //         // setNumberOfUsers(users.length)
      //     });
    }

    // else if (modelData.length === 0 && rotateCall === true) {
    //     console.log("Will not proceed - Will rotate - w");

    // }
    else {
      setLoading(false);
    }

    /*if (rotateCall){
            console.log("Will rotate");
        }
        else{
            console.log("Will not rotate");
        }*/
  }
  /*</Fragment><Scene />
<button id="btn-scene" onClick={onClickDo()} > Click Me <button/>*/
  /* <button type="button" id="btn-scene" onClick={e => {onClickDo(e)}} > Proceed </button> */

  function redirectToFormApp() {
    window.location.href = redirectHP.protocol + redirectHP.host + ":" + redirectHP.port + "/form";
  }

  function closeVideo() {
    if (loading === false) {
      setLoading(true);
    }
  }

  React.useEffect(() => {
    setTimeout(() => {
      if (loading === false) {
        setLoading(true);
      }
    }, 5700);
  }, [loading]);

  async function sendSelected(selected) {
    const selection = {
      teeth: selected.teeth,
      gums: selected.gums,
      tongue: selected.tongue,
    };
    // let selection;
    // if (selected.teeth && selected.gums){
    //     selection = ['teeth', 'gums']
    // }
    console.log("sendSelected: ", selected);
    await axios
      .post(HP.protocol + HP.host + ":" + HP.port + "/api/api/selected", selection)
      .then((res) => {
        console.log("Selection sent to server", res);
        if (res.data === "recaptcha") {
          setShowRecaptcha(true);
        } else {
          redirectToFormApp();
        }
      })
      .catch((err) => {
        console.log("Couldn't send selection to server", err);
      });
  }

  const handleRecaptchaChange = async (responseToken) => {
    try {
      const response = await axios.post(
        HP.protocol + HP.host + ":" + HP.port + "/api/verify-recaptcha",
        { responseToken }
      );
      const { success, message } = response.data;
      setVerificationResult({ success, message });
      console.log(verificationResult);
      setShowRecaptcha(!success);
      console.log(success);
      console.log(message);
    } catch (error) {
      console.error("Error during reCAPTCHA verification:", error.message);
      setVerificationResult({
        success: false,
        message: "An error occurred during reCAPTCHA verification.",
      });
    }
  };

  const handleRecaptchaGet = async () => {
    try {
      const response = await axios.get(
        HP.protocol + HP.host + ":" + HP.port + "/api/verify-recaptcha"
      );
      if (response.data === "recaptcha") {
        setShowRecaptcha(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  React.useEffect(() => {
    handleRecaptchaGet();
  }, []);

  React.useEffect(() => {
    // setScreen({width: window.innerWidth, height: window.innerHeight});
    const updateScreen = () => {
      setScreen({ width: window.innerWidth, height: window.innerHeight });
    };
    updateScreen();

    const handleResize = () => {
      updateScreen();
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      console.log(screen);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {loading ? (
        <Stack
          direction="column"
          spacing={1}
          justifyContent="center"
          alignItems={"center"}
          maxHeight="80vh"
          width={"100%"}
        // alignItems="stretch"
        >
          <h3 style={{ fontWeight: "400", paddingTop: 40, padding: 20 }}>
            Please click the areas of concern?
          </h3>

          <div
            style={{
              zIndex: showRecaptcha ? 10 : -10,
              // border: "2px solid black",
              position: "absolute",
              maxWidth: 500,
              height: 450,
              marginTop: -80,
              marginLeft: -80,
            }}
          ></div>
          <Scene
            modelDataFunc={modelDataFunc}
            isEventAttachedFunc={[isEventAttachedFunc, isEventAttached]}
            rotateCallFunc={[rotateCallFunc, rotateCall]}
            highlightCallFunc={[setHighlightCallFunc, highlightCall]}
            selectedFunc={[selectedFunc, selected]}
          />
          {showRecaptcha ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ReCAPTCHA
                sitekey="6Lcht1wmAAAAAM8GOsgGCXu77Um25Cmbyfb2_84B"
                onChange={handleRecaptchaChange}
              />
            </div>
          ) : null}
          {/* screen.width < 500 ? "320px" : "446px" */}
          <Button
            sx={{ margin: "auto 0%", width: screen.width >= 350 ? "320px" : "100%"}}
            variant="contained"
            color="primary"
            onClick={(e) => {
              onClickDo(e);
            }}
            disabled={showRecaptcha}
          >
            {" "}
            Proceed{" "}
          </Button>
          <div
            style={{
              zIndex: showRecaptcha ? 10 : -10,
              // border: "2px solid black",
              position: "absolute",
              width: 600,
              height: 150,
              marginBottom: -680,
              marginLeft: -580,
            }}
          ></div>
        </Stack>
      ) : (
        <Stack
          direction="column"
          spacing={1}
          justifyContent="center"
          maxHeight="80vh"
        // alignItems="stretch"
        >
          <UserDemo funcClose={closeVideo} />
        </Stack>
      )}
    </ThemeProvider>
  );
}
