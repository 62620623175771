import "./footer.css";
// import BottomNavigation from '@mui/material/BottomNavigation';
// import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import React from "react";
import { Link, Outlet } from "react-router-dom";

export default function Footer() {
  // const [value, setValue] = React.useState(0);
  return (
    <div>
      {/* <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
            >
                <BottomNavigationAction label="Terms"/>
                <BottomNavigationAction label="About"/>
            </BottomNavigation> */}
      {/* include a class css for footer properties */}
      <div className="footer">
        {/* the href basically the link active on click */}
        {/* --when click on the terms then it render the terms page */}
        <Link to="/terms" target="_blank" className="links">
          Terms
        </Link>
        <Link to="/about" target="_blank" className="links">
          About
        </Link>
        <Outlet />
        {/* --when click on the about then it render the about page */}
      </div>
    </div>
  );
}
